import { WebformComponent } from "@brynlabs/webforms-react"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import React, { useState } from "react"

import Button from "../components/button/button"
import FormErrorBanner from "../components/form-error-banner/form-error-banner"
import { WebformsAdditionalData } from "../components/form/additional-data/additional-data"
import { WebformsCheckbox } from "../components/form/checkbox/checkbox"
import { WebformsCountrySelect } from "../components/form/country-select/country-select"
import FormField from "../components/form/form-field/form-field"
import { WebformsInput } from "../components/form/input/input"
import { WebformsLanguageSelect } from "../components/form/language-select/language-select"
import { WebformsTextarea } from "../components/form/textarea/textarea"
import InfoSection from "../components/info-section/info-section"
import PageHeader from "../components/page-header/page-header"
import RichText from "../components/rich-text/rich-text"
import SEO from "../components/seo"
import Tile from "../components/tile/tile"
import TileContentSpacer from "../components/tile/tile-content-spacer/tile-content-spacer"
import { localizedNavigate } from "../i18n/localizedNavigate"
import { useTranslation } from "../i18n/translate"
import { ProductModel } from "../model/productModel"
import useIsClient from "../util/is-client"
import trackCustomEvent from "../util/trackCustomEvent"
import "./request-datasheet.sass"

const RequestDataSheetPage = ({ location }) => {
  const [country, setCountry] = useState()

  const [loading, setLoading] = useState(false)
  const [formSent, setFormSent] = useState(false)
  const [hasError, setHasError] = useState(false)

  const { t } = useTranslation()
  const isClient = useIsClient()

  const {
    p: product_name,
    pid: product_id,
    type,
  } = queryString.parse(useLocation().search)

  console.log(product_id)

  let isEmpty = false
  if (
    typeof window !== "undefined" &&
    (!product_name || !product_id || !type)
  ) {
    isEmpty = true
  }

  console.log(isEmpty)

  let productData = {}

  let recipientEmail = "info@munzing.com"

  if (!isEmpty) {
    productData = { name: product_name, id: product_id }
    const firstKey = Object.keys(
      ProductModel.prepareForWebforms([new ProductModel(productData)], country),
    )[0]
    if (firstKey && firstKey !== "other") {
      recipientEmail = firstKey
    }
  }

  return (
    <>
      <SEO
        pathName={location.pathname}
        indexPage={false}
        titleTranslatedString={t("PRODUCTS-MSDS_TDS_REQUEST-META_TITLE")}
        descriptionTranslatedString={t(
          "PRODUCTS-MSDS_TDS_REQUEST-META_DESCRIPTION",
        )}
      />
      <PageHeader
        container={"tertiary"}
        levelTwoTitle={t("PRODUCTS-MSDS_TDS_REQUEST-PAGE_TITLE")}
        levelThreeTitle={t("PRODUCTS-MSDS_TDS_REQUEST-PAGE_TITLE")}
      />
      <div className={"_fp-global-container-tertiary _fp-grid--margin-top"}>
        <div className="_fp-grid _fp-grid--gap _fp-order-product-sample">
          {!isEmpty && (
            <>
              {!formSent && (
                <div className="_fp-col-12">
                  <Tile
                    titleTranslatedString={t(
                      "PRODUCTS-MSDS_TDS_REQUEST-REQUEST_MSDS_TDS_VARIABLE",
                      {
                        type:
                          type === "msds" && isClient
                            ? t("PRODUCTS-MSDS")
                            : t("PRODUCTS-TDS"),
                      },
                    )}
                  >
                    <WebformComponent
                      onSubmit={() => {
                        setLoading(true)
                        setHasError(false)
                      }}
                      onSuccess={() => {
                        setFormSent(true)
                        setHasError(false)
                        setLoading(false)
                        trackCustomEvent({
                          category: "request_datasheet",
                          action: "form_sent",
                          label: `Request ${type} for ${productData.name}`,
                        })
                      }}
                      onError={() => {
                        setHasError(true)
                        setLoading(false)
                      }}
                      formToken={
                        "pvegLTNQgnCtuPbuCvFGDaoQdbCqKNDOuJjJiggMtOvTWiGXkTAGGdOIDZePnINN"
                      }
                      webformsEndpoint={
                        process.env.GATSBY_TEST_WEBFORMS === "true"
                          ? "https://staging.webforms.brynlabs.com/api"
                          : undefined
                      }
                    >
                      <WebformsAdditionalData
                        webformsKey={"datasheetType"}
                        additionalData={(type || "").toUpperCase()}
                      />
                      <WebformsAdditionalData
                        webformsKey={"product"}
                        additionalData={productData}
                      />
                      <WebformsAdditionalData
                        webformsKey={"recipientEmail"}
                        additionalData={recipientEmail}
                      />
                      <div className="_fp-grid _fp-grid--gap">
                        <div className="_fp-col-12">
                          <FormField
                            fullWidth={true}
                            titleTranslatedString={t("PRODUCTS-PRODUCT") + " *"}
                          >
                            <div className="_fp-request-datasheet__product-grid">
                              <p
                                className={
                                  "_fp-request-datasheet__product-grid__product"
                                }
                              >
                                {isClient && product_name}
                              </p>
                            </div>
                          </FormField>
                        </div>
                        {type === "msds" && isClient && (
                          <>
                            <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                              <FormField
                                fullWidth={true}
                                titleTranslatedString={
                                  t(
                                    "GLOBAL-CONTACT_FORM-MSDS_TDS_LANGUAGE_VARIABLE",
                                    {
                                      type:
                                        type === "msds" && isClient
                                          ? t("PRODUCTS-MSDS")
                                          : t("PRODUCTS-TDS"),
                                    },
                                  ) + " *"
                                }
                              >
                                <WebformsLanguageSelect
                                  required={true}
                                  webformsKey={"language"}
                                  darkAppearance
                                  name={"language"}
                                  allowUndefined={false}
                                />
                              </FormField>
                            </div>
                            <div className="_fp-col-6 _fp-col-0st _fp-col-0p _fp-col-0sp"></div>
                          </>
                        )}
                        <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                          <FormField
                            fullWidth={true}
                            titleTranslatedString={
                              t("GLOBAL-CONTACT_FORM-FULL_NAME") + " *"
                            }
                          >
                            <WebformsInput
                              required={true}
                              webformsKey={"full_name"}
                              darkAppearance
                              name={"full_name"}
                            />
                          </FormField>
                        </div>
                        <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                          <FormField
                            fullWidth={true}
                            titleTranslatedString={
                              t("GLOBAL-CONTACT_FORM-COMPANY_NAME") + " *"
                            }
                          >
                            <WebformsInput
                              required={true}
                              darkAppearance
                              webformsKey={"company_name"}
                              name={"company_name"}
                            />
                          </FormField>
                        </div>
                        <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                          <FormField
                            fullWidth={true}
                            titleTranslatedString={
                              t("GLOBAL-CONTACT_FORM-ADDRESS_LINE_1") + " *"
                            }
                          >
                            <WebformsInput
                              required={true}
                              darkAppearance
                              webformsKey={"address_line_1"}
                              name={"address_line_1"}
                            />
                          </FormField>
                        </div>
                        <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                          <FormField
                            fullWidth={true}
                            titleTranslatedString={t(
                              "GLOBAL-CONTACT_FORM-ADDRESS_LINE_2",
                            )}
                          >
                            <WebformsInput
                              darkAppearance
                              webformsKey={"address_line_2"}
                              name={"address_line_2"}
                            />
                          </FormField>
                        </div>
                        <div className="_fp-col-4 _fp-col-12st _fp-col-12p _fp-col-12sp">
                          <FormField
                            fullWidth={true}
                            titleTranslatedString={
                              t("GLOBAL-CONTACT_FORM-POSTAL_CODE") + " *"
                            }
                          >
                            <WebformsInput
                              required={true}
                              darkAppearance
                              webformsKey={"postal_code"}
                              name={"postal_code"}
                            />
                          </FormField>
                        </div>
                        <div className="_fp-col-8 _fp-col-12st _fp-col-12p _fp-col-12sp">
                          <FormField
                            fullWidth={true}
                            titleTranslatedString={
                              t("GLOBAL-CONTACT_FORM-CITY") + " *"
                            }
                          >
                            <WebformsInput
                              required={true}
                              darkAppearance
                              name={"city"}
                              webformsKey={"city"}
                            />
                          </FormField>
                        </div>
                        <div className="_fp-col-3 _fp-col-12st _fp-col-12p _fp-col-12sp">
                          <FormField
                            fullWidth={true}
                            titleTranslatedString={t(
                              "GLOBAL-CONTACT_FORM-STATE_PROVINCE",
                            )}
                          >
                            <WebformsInput
                              darkAppearance
                              name={"state_province"}
                              webformsKey={"state_province"}
                            />
                          </FormField>
                        </div>
                        <div className="_fp-col-9 _fp-col-12st _fp-col-12p _fp-col-12sp">
                          <FormField
                            fullWidth={true}
                            titleTranslatedString={
                              t("GLOBAL-CONTACT_FORM-COUNTRY") + " *"
                            }
                          >
                            <WebformsCountrySelect
                              onSelect={setCountry}
                              required={true}
                              darkAppearance
                              name={"country"}
                              webformsKey={"country"}
                            />
                          </FormField>
                        </div>
                        <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                          <FormField
                            fullWidth={true}
                            titleTranslatedString={
                              t("GLOBAL-CONTACT_FORM-PHONE") + " *"
                            }
                          >
                            <WebformsInput
                              required={true}
                              darkAppearance
                              name={"phone"}
                              webformsKey={"phone"}
                            />
                          </FormField>
                        </div>
                        <div className="_fp-col-6 _fp-col-12st _fp-col-12p _fp-col-12sp">
                          <FormField
                            fullWidth={true}
                            titleTranslatedString={
                              t("GLOBAL-CONTACT_FORM-E_MAIL") + " *"
                            }
                          >
                            <WebformsInput
                              type={"email"}
                              autoComplete={"email"}
                              required={true}
                              darkAppearance
                              name={"email"}
                              webformsKey={"email"}
                            />
                          </FormField>
                        </div>
                        <div className="_fp-col-12">
                          <FormField
                            fullWidth={true}
                            titleTranslatedString={t(
                              "GLOBAL-CONTACT_FORM-COMMENT",
                            )}
                          >
                            <WebformsTextarea
                              darkAppearance
                              name={"comment"}
                              webformsKey={"comment"}
                            />
                          </FormField>
                        </div>
                        <div className="_fp-col-12">
                          <WebformsCheckbox
                            required={true}
                            large={true}
                            translatedLabel={
                              <RichText
                                textTranslationKey={
                                  "GLOBAL-CONTACT_FORM-PRIVACY_POLICY_MESSAGE"
                                }
                              ></RichText>
                            }
                            darkAppearance={true}
                            name={"privacy"}
                            webformsKey={"privacy"}
                          />
                        </div>
                        <div className="_fp-col-12">
                          {!loading && (
                            <Button
                              buttonSize={"large"}
                              type={"submit"}
                              buttonCallback={() => {
                                document.body.scrollTop = 0 // For Safari
                                document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
                              }}
                              buttonLabel={t(
                                "PRODUCTS-MSDS_TDS_REQUEST-REQUEST_MSDS_TDS_CTA_VARIABLE",
                                {
                                  type:
                                    type === "msds" && isClient
                                      ? t("PRODUCTS-MSDS")
                                      : t("PRODUCTS-TDS"),
                                },
                              )}
                            />
                          )}
                          {loading && (
                            <div>
                              {t("GLOBAL-CONTACT_FORM-SENDING_MESSAGE")}
                            </div>
                          )}
                        </div>

                        {hasError && !loading && (
                          <FormErrorBanner
                            textTranslatedString={t(
                              "GLOBAL-CONTACT_FORM-ERROR_MESSAGE",
                            )}
                            emailAddress={recipientEmail}
                          ></FormErrorBanner>
                        )}
                      </div>
                    </WebformComponent>
                  </Tile>
                </div>
              )}

              {formSent && (
                <div className="_fp-col-12">
                  <Tile
                    titleTranslatedString={t(
                      "GLOBAL-CONTACT_FORM-MESSAGE_SUBMITTED_SUCCESSFULLY",
                    )}
                  >
                    <p>
                      {t("GLOBAL-CONTACT_FORM-CONFIRMATION_MAIL_EXPLAINATION")}
                    </p>
                    <TileContentSpacer size={"small"}></TileContentSpacer>
                    <Button
                      buttonCallback={() => {
                        localizedNavigate("/request-datasheet/")
                      }}
                      buttonLabel={t("GLOBAL-OK")}
                    ></Button>
                  </Tile>
                </div>
              )}
            </>
          )}

          {isEmpty && (
            <div className="_fp-col-12">
              <Tile
                titleTranslatedString={t(
                  "PRODUCTS-MSDS_TDS_REQUEST-PAGE_TITLE",
                )}
              >
                <InfoSection
                  textTranslatedString={t(
                    "PRODUCTS-MSDS_TDS_REQUEST-NO_PRODUCT_SELECTED_INFO",
                  )}
                />
              </Tile>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default RequestDataSheetPage

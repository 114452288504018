import { withFormElement } from "@brynlabs/webforms-react"
import PropTypes from "prop-types"
import React from "react"

import { Locale } from "../../../i18n/locale"
import { withTranslation } from "../../../i18n/translate"
import Select from "../select/select"

class LanguageSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: Locale.getFallbackLocale(),
      languages: Locale.getAllMsdsLocales().sort((a, b) =>
        a.getTranslatedLocaleName().localeCompare(b.getTranslatedLocaleName()),
      ),
    }
  }

  componentDidMount() {
    if (this.props.onSelect) {
      this.props.onSelect(this.state.language)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({ language: this.props.value })
    }
    if (prevProps.valueAsString !== this.props.valueAsString) {
      this.setState({
        language: this.state.languages.find(
          (l) => l.getEnglishLocaleName() === this.props.valueAsString,
        ),
      })
    }
  }

  setLanguage(language) {
    if (this.props.onSelect) {
      this.props.onSelect(language)
    }
    this.setState({
      language,
    })
  }

  render() {
    const { language, languages } = this.state
    const { t, fullWidth, valueAsString, ...props } = this.props

    return (
      <Select
        {...props}
        values={languages}
        name={"country"}
        mapValueToString={(val) => t(val?.getLanguageTranslationKey())}
        onSelect={(val) => this.setLanguage(val)}
        value={language}
      />
    )
  }
}

LanguageSelect.propTypes = {
  fullWidth: PropTypes.bool,
}

const LanguageSelectHoc = withTranslation()(LanguageSelect)

const WebformsLanguageSelect = withFormElement(
  ({ setData, getData, ...props }) => {
    return (
      <LanguageSelectHoc
        onSelect={(locale) => {
          setData(locale?.getEnglishLocaleName() || "")
        }}
        valueAsString={getData()}
        {...props}
      />
    )
  },
)

export { LanguageSelectHoc as LanguageSelect, WebformsLanguageSelect }
